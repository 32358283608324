var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-container"},[_c('eHeader',{attrs:{"sup_this":_vm.sup_this,"query":_vm.query}}),_c('CommonTable',{attrs:{"tableLoading":_vm.tableLoading,"cols":_vm.cols,"infoData":_vm.data,"selection":false},on:{"selection-change":_vm.handleSelectionChange},scopedSlots:_vm._u([{key:"typeSlot",fn:function(ref){
var row = ref.scoped;
return [_vm._v(" "+_vm._s(row.type_name)+" ")]}},{key:"is_activeName",fn:function(ref){
var row = ref.scoped;
return [(row[row.prop])?_c('el-tag',{attrs:{"size":"small","type":"primary"}},[_vm._v(" 是 ")]):_c('el-tag',{attrs:{"size":"small","type":"danger"}},[_vm._v(" 否 ")])]}},{key:"rolesSlot",fn:function(ref){
var row = ref.scoped;
return _vm._l((row[row.prop] || []),function(ref){
var id = ref.id;
var name = ref.name;
return _c('el-tag',{key:id,staticStyle:{"margin":"5px"},attrs:{"type":"primray","size":"mini"}},[_vm._v(" "+_vm._s(name)+" ")])})}}])},[_vm._t("default",[_c('el-table-column',{attrs:{"fixed":"right","label":"操作","width":"100px","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('color-text-btn',{attrs:{"type":"danger"}},[_vm._v(" 解禁 ")])]}}])})])],2),_c('PaginationBar',{ref:"paginationBar",attrs:{"total":_vm.total},on:{"refreshTableEventFun":_vm.refreshTableEventFun}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }